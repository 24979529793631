/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/conductive/'], ['en', 'https://www.hear.com/hearing-loss/conductive/'], ['en-US', 'https://www.hear.com/hearing-loss/conductive/'], ['en-CA', 'https://ca.hear.com/hearing-loss/conductive/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-diagnosis--treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-diagnosis--treatment",
    "aria-label": "causes diagnosis  treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes, diagnosis & treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the ear, the air converts the sound into action potentials of the nerve fibers via a mechanical chain (eardrum, ossicles and labyrinth). This process is called transduction. In conduction hearing loss, this transduction is impaired and hearing is therefore limited."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following contains important information on conductive hearing loss. On this page, we provide you with information on the causes, the symptoms, the diagnosis by an ENT specialist and possible forms of treatment. Our hearing aid experts can answer your questions by telephone – free of charge and without any obligation from you. Simply give us a call."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-conductive-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-conductive-hearing-loss",
    "aria-label": "causes of conductive hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of conductive hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Conductive hearing loss can be caused by problems in the external ear canal or in the middle ear. Possible causes include illnesses such as otosclerosis, cholesteatoma or inflammatory processes accompanied by middle ear effusion or scarring."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another frequent factor that causes conductive hearing loss is a buildup of earwax (cerumen), another foreign body, or otitis externa. Following an accident, a fractured petrous bone can also cause conductive hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/conductive-hearing-loss.png",
    alt: "Conductive hearing loss representation",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms",
    "aria-label": "symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In conductive hearing loss, sounds in everyday life are perceived as softer and discussions feel more strenuous. However, the quality of the sounds is not clear and sharp – the perception of both high and low tones is reduced. People with these symptoms frequently describe the feeling of “hearing through cotton wool” or “wearing ear plugs.”"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Several options exist to diagnose conductive hearing loss. An otoscopy can rule out an injured eardrum, foreign bodies in the ear canal (such as ear wax), and middle ear effusion."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During the Weber test, a tuning fork is struck and placed on the crown of the head. A person with normal hearing should be able to perceive the sound equally in both ears. If a patient has conductive hearing loss, the sound travels to the impaired ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During the Rinne test, a tuning fork is struck and placed on the mastoid bone behind the ear and the patient signals when he can no longer hear the tone. Then the (still oscillating) tuning fork is moved near the ear canal and the patient again signals when he can no longer hear the tone. In cases of normal hearing, the mastoid bone conduction sound is heard for half the amount of time as the second sound. If a patient has conductive hearing loss, mastoid bone conduction sound is heard for a longer or equal amount of time than the second sound."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A tympanogram measures the mobility of the eardrum. If the middle ear has the same pressure as the external ear canal, the eardrum will oscillate normally. If there is a difference in pressure, the oscillation behavior and thus the sound conduction will change. This examination detects whether there is an increased pressure (e.g. in the event of secretory otitis media) or a reduced pressure (e.g. in the event of impaired ventilation) in the tympanic cavity, which effectively localizes the cause of conductive hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Pure-tone audiometry distinguishes thresholds between impaired bone and air conduction, and differentiates between sensorineural and labyrinthine hearing loss. Defects in the labyrinth, which can lead to conductive hearing loss, cause the air conductance curve to be at higher decibel values than bone conductance. In other words, aid conduction requires a significantly louder tone to achieve a degree of perception comparable with bone conduction. When this happens, sound is conducted less efficiently via the airway."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-treatments-are-available-for-conductive-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-treatments-are-available-for-conductive-hearing-loss",
    "aria-label": "what treatments are available for conductive hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What treatments are available for conductive hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Depending on the cause of the impairment, treatment for conductive hearing loss may be simple or complicated. Impairment caused by earwax is quickly and easily rectified manually. Even impaired ventilation in the Eustachian tube can be remedied quickly with the help of medication, e.g. decongestant nasal spray."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the event of secretory otitis media, drainage through a small incision in the eardrum (paracentesis) may be necessary to alleviate hearing impairment. The small incision will heal by itself. If secretory otitis media occurs more frequently – especially during childhood – a ‘grommet’ can be inserted to hold open the hole in the eardrum and prevent further inflammation and associated effusions. If hearing is impaired by more serious causes, such as otosclerosis or inflammatory processes that have lead to the destruction of the ossicles, more extensive treatment procedures must be considered. However, modern surgical procedures have enabled the replacement of the ossicles and parts thereof (stapes plasty/tympanoplasty). Hearing aids are also an effective treatment for conductive hearing loss – and ultimately help restore hearing."), "\n", React.createElement(ButtonCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
